import { Component, NgModule, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InvestorsService } from '../../services/investors.service';
import { fromEvent, Subscription, timer } from 'rxjs';
import { IInvestorLookupApiResponse } from '../../models/investor/IInvestorLookupApiResponse';
import { FormGroupTyped } from 'typed-reactive-forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Problem } from '../../models/Problem';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from '../../pipes/pipes.module';
import { SearchBy } from '../../enums/search-by-enum';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-investor-crm-search',
  templateUrl: './investor-crm-search.component.html',
  styleUrls: ['./investor-crm-search.component.scss'],
})
export class InvestorCrmSearchComponent implements OnInit, OnDestroy {

  @Input() investorSearchFormId = "investor-search-form";
  @Input() searchResultDisplayLimit = 30;
  @Input() selectionHidden = false;
  @Input() searchByAdviser: boolean;
  @Output() searchSelection = new EventEmitter<IInvestorLookupApiResponse>();

  investorArray: any[] = [];
  portfoliosArray: any[] = [];
  investorSearchForm: FormGroupTyped<IInvestorSearchForm>;
  subscriptions$: Subscription[] = [];
  investorSearchResults: IInvestorLookupApiResponse[] = [];
  errorMessage = "";
  showSearchResults = false;
  searchInProgress = false;
  noResultsFound = false;
  responsemessage: string = "";
  searchBy: any;
  manuallySet = false;

  constructor(private fb: FormBuilder, private investorService: InvestorsService) {}

  ngOnInit() {
    this.investorSearchForm = this.initInvestorSearchInputForm();
    if(this.searchByAdviser) {
      this.investorSearchForm.controls.searchBy.setValue(SearchBy.Adviser);
    }
    this.subscriptions$.push(this.setupAutoSearchSubscription(), this.windowEventsSubscription());
  }

  public initInvestorSearchInputForm(): FormGroupTyped<IInvestorSearchForm> {
    this.searchBy = this.populateSearchBy(this.searchByAdviser);

    return this.fb.group({
      searchBy: [SearchBy.Investor, Validators.required],
      searchInput: ['', Validators.required]
    }) as FormGroupTyped<IInvestorSearchForm>;
  }

  public searchInvestor(e?: Event) {
    if (this.searchInProgress || this.manuallySet) {
      this.manuallySet = false;
      return;
    }

    const searchQuery = this.investorSearchForm.controls.searchInput.value.trim();
    if (searchQuery.length < 3) {
      return;
    }

    if (this.containsOnlySpecialChars(searchQuery) || this.numberFollowedBySpecialChar(searchQuery) || this.alphaFollowedBySpecialChar(searchQuery)) {
      this.responsemessage = "(zero) investor matches";
      this.noResultsFound = true;
      this.searchInProgress = false;
      return;
    }

    this.searchInProgress = true;
    const searchBy = this.searchByAdviser ? SearchBy.Adviser : this.investorSearchForm.controls.searchBy.value;

    this.investorService.searchInvestors(searchBy, searchQuery).subscribe(
      (result: IInvestorLookupApiResponse[]) => {
        this.investorSearchResults = result || [];
        if (this.investorSearchResults.length) {
          this.populateDisplayName(searchQuery);
          this.showSearchResults = true;
          this.noResultsFound = false;
          this.errorMessage = "";
        } else {
          this.responsemessage = "There are no matches on your search.";
          this.noResultsFound = true;
        }
      },
      (errorResponse: Problem) => {
        this.searchInProgress = false;
        this.noResultsFound = false;
        this.errorMessage = "The search has errored. Please try again.";
      },
      () => {
        this.searchInProgress = false;
        this.errorMessage = "";
      }
    );
  }

  private populateDisplayName(searchQuery: string) {
    const searchItem = searchQuery.replace(/\s+/g, " ").trim();
    this.investorSearchResults.forEach((investor) => {
      const searchByNumber = +this.investorSearchForm.controls.searchBy.value;
      switch (searchByNumber) {
        case SearchBy.Investor:
          investor.searchBy = SearchBy.Investor;
          investor.displayName = this.formatDisplayName(investor.shareholderId, investor.legalName, searchItem);
          break;
        case SearchBy.PortfolioRef:
          investor.searchBy = SearchBy.PortfolioRef;
          const portfolioRef = investor.portfolios[0].reference;
          investor.displayName = `${this.makeBold(portfolioRef, searchItem)} - ${investor.shareholderId} - ${this.makeBold(investor.legalName, searchItem)}`;
          break;
        case SearchBy.Adviser:
          investor.searchBy = SearchBy.Adviser;
          investor.displayName = this.makeBold(investor.legalName, searchItem);
          break;
      }
    });
  }

  private formatDisplayName(shareholderId: string, legalName: string, searchItem: string): string {
    if (shareholderId && shareholderId.includes(searchItem)) {
      return `${this.makeBold(shareholderId, searchItem)} - ${legalName}`;
    } else {
      return `${shareholderId} - ${this.makeBold(legalName, searchItem)}`;
    }
  }

  private makeBold(input: string, wordsToBold: string): string {
    const searchRegex = new RegExp(wordsToBold, 'gi');
    return input.replace(searchRegex, '<b>$&</b>');
  }

  public onSearchItemSelection(value: IInvestorLookupApiResponse) {
    this.investorSearchForm.controls.searchInput.setValue(value.legalName);
    this.searchSelection.emit(value);
    this.investorSearchResults = [];
    this.manuallySet = true;
  }

  public windowEventsSubscription() {
    return fromEvent(window, 'resize')
      .pipe(
        mergeMap(() => fromEvent(window, 'click')),
        filter(() => !this.searchInProgress),
        debounceTime(250)
      )
      .subscribe((event: Event) => {
        if (!(event.target as HTMLElement).closest(`#${this.investorSearchFormId}`)) {
          this.clearSearchResults();
        }
      });
  }

  setupAutoSearchSubscription() {
    return this.investorSearchForm.controls.searchInput.valueChanges
      .pipe(
        debounceTime(1000),
        filter(() => this.investorSearchForm.controls.searchInput.valid && !!this.investorSearchForm.controls.searchInput.value.trim())
      )
      .subscribe(() => this.searchInvestor());
  }

  clearSearchResults() {
    this.investorSearchResults = [];
    this.showSearchResults = false;
    setTimeout(() => this.noResultsFound = false, 300);
  }

  private containsOnlySpecialChars(value: string): boolean {
    return /^[^a-zA-Z0-9]+$/.test(value);
  }

  private numberFollowedBySpecialChar(value: string): boolean {
    return /^\d*[0-9][^0-9A-Za'-z]/.test(value);
  }

  private alphaFollowedBySpecialChar(value: string): boolean {
    return /^\d*[A-Za-z]+[^A-Za-z0-9'-\s]/.test(value);
  }

  private populateSearchBy(includeAdviser: boolean) {
    return Object.keys(SearchBy)
      .filter(key => typeof SearchBy[key as keyof typeof SearchBy] === 'number')
      .map(key => ({
        id: SearchBy[key as keyof typeof SearchBy],
        name: `Search by ${key.split(/(?=[A-Z])/).join(" ")}`
      }))
      .filter(item => includeAdviser || item.id !== SearchBy.Adviser);
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(subscription => subscription.unsubscribe());
  }
}

@NgModule({
  declarations: [InvestorCrmSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [CommonModule, InvestorCrmSearchComponent]
})
export class InvestorCrmSearchModule { }

interface IInvestorSearchForm {
  searchBy: SearchBy;
  searchInput: string;
}
