import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            if (error.headers.get('content-type')?.startsWith('application/problem+json')) {
              let problem = error.error as ProblemDetails;

              if (problem.detail) {
                return throwError(problem);
              }

              problem = JSON.parse(error.error) as ProblemDetails;
              return throwError(problem);
            }

            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }

          return throwError(errorMessage);
        })
      );
  }
}

export interface ProblemDetails {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance?: string;
  traceId: string;
  downingCode?: string;
  friendlyMessage: string;
}
