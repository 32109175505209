export const MY_MAT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY'
  },
  display: {
    dateInput: 'DD MMM YYYY',
    datetimeInput: 'DD MMM YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    dateTimeInputHrs: 'DD-MMM-YYYY HH:mm'
  },
};

export const MY_MAT_MOMENT_DATE_ADAPTER_OPTIONS = {
  useUtc: true
};

export const MY_MAT_FORM_FIELD_DEFAULT_OPTIONS = {
  appearance: 'outline'
};

export const MY_MAT_SNACK_BAR_DEFAULT_OPTIONS = {
  duration: 5000,
  horizontalPosition: 'center',
  panelClass: 'notification',
  verticalPosition: 'top'
};

export const MY_MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS = {
  diameter: 16
};

export const MY_MAT_PAGINATOR_DEFAULT_OPTIONS = {
  pageSize: 50,
  pageSizeOptions: [10, 25, 50, 100],
  showFirstLastButtons: true
};

export const MY_MAT_TABS_CONFIG = {
  animationDuration: '500ms',
  dynamicHeight: true
};

export const IDENTITY_API_ERROR_CODES = {
  investorNotFound: 'I0006',
  adviserNotFound: 'I0007'
};

export const BOP_ERROR_CODES = {
  approvalRequestNotFoundException: 'BOP0028',
  approvalRequestNotInExpectedStatusException: 'BOP0029',
  approvalRequestAlreadyExistsException: 'BOP0040',
  companyShareClassValuationNotFound: 'BOP0005',
  companyValuationAlreadyExists: 'BOP0006',
  securityClassAlreadyExists: 'BOP0012',
  transferApprovalException: 'BOP0041',
  transferRejectionException: 'BOP0042',
  transferSendForSecondApprovalException: 'BOP0043',
};

export const REGULAR_EXPRESSION_PATTERNS = {
  sharePrice: /^\d{0,10}(\.\d{1,10})?$/,
  numericOnly: /^[0-9]*$/,
  alphaNumericOnly: /^[a-zA-Z0-9]*$/,
  alphaNumericSpaceOnly: /^[a-zA-Z0-9 ]*$/,
  uuid: /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i
};

export const FEATURES = {
  sharePrices: {
    persistToWarehouse: 'Bop_SharePrices_PersistToWarehouse'
  },
  documentGeneration: {
    v2: 'Bop_DocumentGeneration_DocumentGenerationV2'
  },
  piDataRefresh: {
    enabled: 'Bop_PiDataRefresh_Enabled'
  },
  exits: {
    enableExtendedExitRequests: 'Bop_Exits_EnableExtendedExitRequests'
  }
};

export const APPLICATION_CLASS = {
  standard: 'Standard',
  rightsIssue: 'RightsIssue',
};

export const DEPS_SECURITY_CODES = {
  bagnall: 'Bagnall',
  pulford: 'Pulford'
};

export const SHARE_CLASS_CODES = {
  ordinaryA: 'Ord A'
};

export const ALLOTMENT_DOWNLOAD_TYPES = {
  forContractNotes: 'CN',
  forBiTeam: 'BI',
  forBank: 'TT',
  forWorkings: 'Workings'
};

export const APPROVAL_REQUEST_STATUS = {
  pending: 'Pending',
  approved: 'Approved',
  postponed: 'Postponed',
  processed: 'Processed',
  sentForApproval: 'SentForApproval',
  inProgress: 'InProgress'
};

export const APP_ROLES = {
  adhocValuationsAdministrator: 'AdhocValuations.Administrator',
  contractNotesAdministrator: 'ContractNotes.Administrator',
  costsAndChargesAdministrator: 'CostsAndCharges.Administrator',
  depsApprove: 'Deps.Approve',
  depsRequest: 'Deps.Request',
  depsView: 'Deps.View',
  exitsReadWrite: 'Exits.ReadWrite',
  exitsSecondApprove: 'Exits.SecondApprove',
  exitsPostApprovalWrite: 'Exits.PostApproval.Write',
  taxStatementAdministrator: 'TaxStatements.Administrator',
  fundAdministrator: 'Fund.Administrator',
  rightsIssueAdministrator: 'RightsIssue.Administrator',
  transfersReadWrite: 'Transfers.ReadWrite',
  transfersSecondApprove: 'Transfers.SecondApprove',
  valuationsAdministrator: 'Valuations.Administrator',
};

export const EXIT_TYPE_OPTIONS: string[] = [
  'Full',
  'Partial',
  'Life cover',
  'Distribution',
  'Correction',
  'Ongoing adviser charge',
  'Initial adviser charge',
  'Annual Trail Commission payable to Wealth Club'
];
